import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Concepts from "../components/concepts";

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Layout>
      <SEO title="Sorted Coder" />
      <Concepts data={edges} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: frontmatter___concept }
      filter: { frontmatter: { parent: { eq: true } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            concept
            description
          }
        }
      }
    }
  }
`;
