import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import JavaImg from "../images/cards/java.jpg";
import JavascriptImg from "../images/cards/javascript.jpg";
import BashImg from "../images/cards/bash.jpg";
import EnglishImg from "../images/cards/english.jpg";
import RegExImg from "../images/cards/regex.jpg";
import LearningImg from "../images/cards/learning.jpg";
import DataStructures from "../images/cards/datastructures.jpg";
import Algorithms from "../images/cards/algorithms.jpg";
import Python from "../images/cards/python.png";
import Lambda from "../images/cards/lambda.png";

const imageMap = {
  Java: JavaImg,
  Javascript: JavascriptImg,
  Bash: BashImg,
  "Code without English": EnglishImg,
  Regex: RegExImg,
  Learning: LearningImg,
  DataStructures: DataStructures,
  Algorithms: Algorithms,
  "Functional Programming": Lambda,
  "Python Mind Maps":Python,
};
const clampDescStyle = {"width": "100%",
"display": "-webkit-box",
"-webkit-box-orient": "vertical",
"-webkit-line-clamp": "4",
"overflow": "hidden"
};
const clampStyle = {"width": "100%",
"height": "4rem",
"display": "-webkit-box",
"-webkit-box-orient": "vertical",
"-webkit-line-clamp": "2",
"overflow": "hidden"
};

export default function concepts(props) {
  return (
    <div>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {props.data.map((eachConcept) => (
          <Grid item xs={3} key={eachConcept.node.id} style={{"margin-bottom":"1rem"}}>
            <Card>
              <CardActionArea href={eachConcept.node.frontmatter.path} style={{"padding-bottom": ".5rem","box-shadow":"none"}}>
                <CardMedia
                  component="img"
                  alt={eachConcept.node.frontmatter.concept}
                  height="140"
                  image={imageMap[eachConcept.node.frontmatter.concept]}
                  title="Contemplative Reptile"
                />
                <CardContent style={{"height": "10rem"}}>
                  <Typography gutterBottom variant="h5" component="h2" style={clampStyle}>
                    {eachConcept.node.frontmatter.concept}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={clampDescStyle}
                  >
                    {eachConcept.node.frontmatter.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  href={eachConcept.node.frontmatter.path}
                  style={{margin: "8px 4px"}}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
